<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p>
        Click the buttons below to construct the proper
        <span class="text-bold">full</span> electron configuration for the
        <chemical-ion :symbol="ecElement" :charge="ionCharge" />
        anion.
      </p>

      <electron-configuration-input
        v-model="inputs.input1"
        :max-n="4"
        :max-occupation="10"
        :orbital-types="['s', 'p', 'd']"
      />

      <p class="mt-2 mb-2">
        What is the chemical symbol for the Noble gas that shares this electron configuration?
      </p>
      <chemical-symbol-input
        v-model="inputs.input2"
        class="mb-2"
        prepend-text="$\text{Element:}$"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ElectronConfigurationInput from '../inputs/ElectronConfigurationInput';
import ChemicalIon from '../displayers/ChemicalIon';
import ChemicalSymbolInput from '../inputs/ChemicalSymbolInput';

export default {
  name: 'Question27',
  components: {
    ElectronConfigurationInput,
    ChemicalIon,
    ChemicalSymbolInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: [],
        input2: null,
      },
    };
  },
  computed: {
    nobleGas() {
      return this.taskState.getValueBySymbol('element').content;
    },
    ionChargeNumber() {
      return this.taskState.getValueBySymbol('ecCharge').content;
    },
    ionCharge() {
      if (this.ionChargeNumber.value === 1) {
        return '-';
      } else if (this.ionChargeNumber.value === 2) {
        return '2-';
      } else if (this.ionChargeNumber.value === 3) {
        return '3-';
      }
    },
    ecElement() {
      if (this.ionCharge === '-') {
        if (this.nobleGas.name === 'Neon') {
          return 'F';
        } else if (this.nobleGas.name === 'Argon') {
          return 'Cl';
        } else if (this.nobleGas.name === 'Krypton') {
          return 'Br';
        }
      } else if (this.ionCharge === '2-') {
        if (this.nobleGas.name === 'Neon') {
          return 'O';
        } else if (this.nobleGas.name === 'Argon') {
          return 'S';
        } else if (this.nobleGas.name === 'Krypton') {
          return 'Se';
        }
      } else if (this.ionCharge === '3-') {
        if (this.nobleGas.name === 'Neon') {
          return 'N';
        } else if (this.nobleGas.name === 'Argon') {
          return 'P';
        } else if (this.nobleGas.name === 'Krypton') {
          return 'As';
        }
      }
    },
  },
};
</script>
